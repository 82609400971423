
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/index.vue?macro=true";
import { default as indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta } from "/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta?.name ?? "index",
    path: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta?.path ?? "/",
    props: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta?.props ?? false,
    meta: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta || {},
    alias: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta?.alias || [],
    redirect: indexYF50T_45IMzz6hc2FDJL6_4517FfFUccSq2iUV3afoKvqAEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/index.vue")
  },
  {
    name: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta?.name ?? "lang",
    path: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta?.path ?? "/:lang?",
    props: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta?.props ?? false,
    meta: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta || {},
    alias: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta?.alias || [],
    redirect: indexxdo49eeEEW4PtNMK9chob3d2h1fazlp5Csk6H1JMV5IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta?.name ?? "lang-voucher-start",
    path: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta?.path ?? "/:lang?/voucher/start",
    props: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta?.props ?? false,
    meta: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta || {},
    alias: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta?.alias || [],
    redirect: startob_45rrFaHgTova7iiMJRic72p_45msOxOaMlKgtdDereJgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta?.name ?? "lang-purchase-start",
    path: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta?.path ?? "/:lang?/purchase/start",
    props: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta?.props ?? false,
    meta: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta || {},
    alias: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta?.alias || [],
    redirect: start60T8KCrkvRInLc_sS98Ov1MjimNGN5Rwi7_WWiDwQksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta?.props ?? false,
    meta: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta || {},
    alias: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta?.alias || [],
    redirect: checkoutI379C0ayykBpw1VuoEmuk1BIpm9L2oH9Ha_qjS24x1UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta?.props ?? false,
    meta: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta || {},
    alias: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta?.alias || [],
    redirect: kobcLXyZwXYbO7uUNEQKXPV_45sUoG_HkvFqJgTae5oxaTkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta?.props ?? false,
    meta: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta || {},
    alias: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta?.alias || [],
    redirect: confirmationkeb1DcYyb77pk5bkskg95qvXrgsrwNrQF7U1ZCIg6qIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta?.props ?? false,
    meta: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta || {},
    alias: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta?.alias || [],
    redirect: confirmationx2mXTYjr6NmtrKI_45yz6LuRnsFtd3VpQDykTlqN0qFDUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta?.props ?? false,
    meta: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta || {},
    alias: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta?.alias || [],
    redirect: buyer2hjN3jLqfF6WERqovsUsBmDwjlTfRw_3nHdFUfPHTssMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta?.props ?? false,
    meta: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta || {},
    alias: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta?.alias || [],
    redirect: buyer3kJv7CJik5TmiDRtJ4nTRF_45Ink87f_45ZvvWBb39xOh8kMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta?.props ?? false,
    meta: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta || {},
    alias: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta?.alias || [],
    redirect: paymentoEgYrXDeJR0Sk5OY9xodSnkKd70Itw0XwOCUEH3vsykMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta?.props ?? false,
    meta: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta || {},
    alias: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta?.alias || [],
    redirect: challenge7_l1_45AaMzUzW9ukuVQLIp79My3_45WUQ_txuvRi2tALTsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta?.props ?? false,
    meta: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta || {},
    alias: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta?.alias || [],
    redirect: payment0_uK9fIhQUYD_45ArFwIGQTCMLnnNF01bEGFBagggREuEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/alicante/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/altea/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/benidorm/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/calpe/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/denia/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/l-alfas-del-pi/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/la-nucia/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/mutxamel/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/rojales/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/alicante/teulada/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/arenys-de-mar/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/barcelona/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/berga/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/caldes-de-montbui/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/calella/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/la-garriga/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/masquefa/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/mataro/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/sant-joan-despi/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/seva/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/sitges/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/barcelona/vallromanes/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 27",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/comunidad-de-madrid/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 28",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 29",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/caldes-de-malavella/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 30",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/castello-dempuries/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 31",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/girona/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 32",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/llanars/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 33",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/lloret-de-mar/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 34",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/sant-feliu-de-guixols/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 35",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/torrent/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 36",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/girona/toses/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 37",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 38",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/almunecar/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 39",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/alpujarra-de-la-sierra/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 40",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/granada/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 41",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/lanjaron/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 42",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/loja/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 43",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/monachil/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 44",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/motril/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 45",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/granada/niguelas/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 46",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/madrid/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 47",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/madrid/colmenar-viejo/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 48",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/madrid/grinon/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.name ?? "Spa by location landing: 49",
    path: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.path ?? "/spa-balneario/madrid/madrid/",
    props: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.props ?? false,
    meta: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta || {},
    alias: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.alias || [],
    redirect: SpaList_46pageOhHU5SppiAOC0Dq_sMEW2PNlau3ZhEvujRL4QyOf564Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/beer-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/shama-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/spa-aquabody/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/luxor-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/inner-flow/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 42",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 43",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 44",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 45",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 46",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 47",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 48",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 49",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 50",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 51",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 52",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 53",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 54",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 55",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 56",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 57",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 58",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-seda-club/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 59",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/elke-spa-hotel/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 60",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 61",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 62",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 63",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 64",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 65",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 66",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 67",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 68",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 69",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 70",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/girona/spa-la-collada/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 71",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 72",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/madrid/esenzias-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 73",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 74",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.name ?? "Spa landing: 75",
    path: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.props ?? false,
    meta: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta || {},
    alias: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.alias || [],
    redirect: SpaDetail_46pagef6FqaTVTAaaQwWZyOataNoukCaxQNbeIvXwzBnZ3_45F8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-parejas-circuito-arabe-masaje-de-55-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-al-andalus/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-califa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-de-chocoterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-gold-theraphy/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-25-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-15-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-pindas-herbales-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-menu-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-privado-y-masaje-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-privado-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/envoltura-facial-y-corporal-chocolate/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-completo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-total/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-geotermal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-hawaiano-lomi-lomi/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-californiano/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-ayurvedico-con-pindas-de-hierba/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-y-masaje-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-localizado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-20-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-hydraluronic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-provenza/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-20-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-menores-4-a-17anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-placido-sueno/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reina-de-egipto-circuito/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-45/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-para-dos-masaje-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-armonia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-consecutivo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/masaje-karite-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet-masaje-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-completo-de-75min-con-aceite-de-cerveza-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/ritual-chocoterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-15-min-cena-2-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-55-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-motril/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-25-min-cena-2-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/envoltura-de-barro-autocalentador/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/masaje-aromatico-con-exfoliacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/diamond-energy/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/love-bliss/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/chocolate-bliss/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/hot-stone-harmony/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/aromatherapy-power/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/citrus-power/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/peeling-corporal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/ritual-spa-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/circuito-de-aguas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/escapada-parejas-estancia-masaje-spa-piscina-fitness/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/prenatal-y-posnatal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/esencias-y-sentidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/chocolate/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/barro/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-podal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-corporal-ayurveda/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/hydraluronic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-hawaiano/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-adulto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-care-para-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-eternal-love-para-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-sensualidad/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-alegria/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-antiestres/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/kids-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-2-ninos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/gourmet-love-para-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-gourmet-para-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-craneofacial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-especial-ninos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-lomi-lomi-con-aceite-de-coco/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/serenidad-citrica/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa-experiencia-gastronomica-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-especial-embarazadas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-relajacion-general/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/tesoro-de-bienestar/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/rio-ter/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa-masaje-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-a-4-manos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-con-piedras-calientes-y-frias/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-de-tierra-y-cielo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-saba/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/galletas-del-valle-de-camprodon/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-individual-de-spa-lunes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-de-spa-pareja-lunes-a-jueves-hasta-las-1530/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-de-spa-masaje-pareja-lunes-a-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-de-spa-pareja-lunes-a-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-de-spa-masaje-y-bombones-pareja-viernes-a-domingo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-local-o-craneo-facial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-mousse-de-chocolate/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/marine-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/albir-relax/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-detox/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-aromaterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/vip-senses/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-relajante-general/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-ninos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-martes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-martes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-geotermal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/glow-flow-spa-ritual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/deep-tissue-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/aromatherapy-back-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/rejuvenate/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/balmoral-signature-ritual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/time-ritual-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/antistress-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relajacion-masaje-y-desayuno/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/glow-recovery-facial-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-signature-facial-by-scens-40-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-65-minutos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-silver-edition/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desconecta-para-conectar/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/cuidado-maternal-65min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-de-25-minutos-infantil/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/escapada-relax-foodie-spa-menu-almuerzo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/circuito-aquatherma/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/circuito-aquatherma-con-comida-cena/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-familiar-mascarilla-facial-solidaria/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/silhoutte-perfection-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hot-stone-bliss-90/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/deep-tissue-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/exfoliation-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/time-ritual-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/antiestres-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/aromatherapy-back-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/sir-victor-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/eternal-youth/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/exfoliation/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/deep-tissue-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/slow-glow-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/cityscape-spa-masaje-y-gastronomia-en-el-rooftop/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-y-circuito-spa-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-serena-spa-luxury-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/white-light/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-couple-cocoon-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-spa-privado-90/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hot-stone-bliss/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-acceso-general-circuito-de-aguas-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/digital-wellness/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/melia-exclusive-signature-ritual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/time-ritual-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/deep-tissue-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/exfoliation/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/limpieza-profunda-con-hidromass/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/higiene-facial-expres/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-chocolaterapia-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-lirio/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-jade/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-relajante-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/exfoliacion-corporal-hidratacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-orquidea/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-descontracturante-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-loto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mediterranean-flavours-90/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/signature-journey/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sweet-orange-body-scrub/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rejuvenate-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-con-opcion-masaje-y-cena-para-2-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-pindas-con-extracto-de-cerveza-completo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/digital-wellness-escape/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/experiencia-gastro-wellness-con-almuerzo-o-cena-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/aromatherapy-back-massage-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/white-light/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/body-ritual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/exfoliation/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/wellness-area-50-acceso-general/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/despertar-by-serena-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/experiencia-wellness-en-familia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/experiencia-wellness-belleza-en-familia-mascarillas-solidarias/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/princess-dreamcatcher/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/deep-tissue-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/time-ritual-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-relax-con-vela-aromatica-de-75-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-beauty-tratamiento-facial-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-con-vela-circuito-spa-90min-masaje-relajante-con-vela-aromatica-de-75min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-serenity-circuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-buenos-dias-desayuno-buffet-circuito-spa-90min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-gastro-relax-comida-cena-agua-y-vino-de-la-casa-incl-circuito-spa90min-buffet-o-menu-segun-ocupacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-renovadora-circuito-spa-de-90min-limpieza-facial-basica-masaje-orient-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-supreme-minimo-2-personas-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-supreme-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-envolvente-circuito-spa-90min-ritual-corporal-con-exfoliacion-y-envolturalimpieza-facial-basica/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-serenitycircuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-min-2-personas-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-spa-de-90min-a-partir-de-15-anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-holistico-de-piedras-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-exclusive-spa-privado-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/2x1-spazio-scape-circuito-termal-60min-lunes-a-viernes-11h-14h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal-masaje-aromaterapia-40min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/peeling-con-hidratacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/age-defense-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/ocean-miracle-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sensations-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/higiene-activa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/cacao-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/infinity-60min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/secretos-del-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/deep-tissue-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/bambu-detox-80min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-lovers-circuito-termal-1h-masaje-cacao-tamakuru-40min-para-dos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-zen-circuito-termal-60min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-celebrate-spa-privado-grupos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/holistico-del-tibet-90min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-luxury-circuito-termal-120min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/sprunch-spa-60-brunch/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/glow-facial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/sultana-de-saba/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/circuito-spa-masaje-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/circuito-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/circuito-spa-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/tratamiento-facial-antioxidante-de-vitamina-c/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/chocolaterapia-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje-y-cena/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet-con-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-cena/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-cena-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/circuito-spa-clientes-externos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-jaccuzi-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/dia-en-inner-flow/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/bienvenido-a-tu-primera-experiencia-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje-en-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-vez-primer-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-experiencia-en-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adultos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-kobido-emperatriz/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-flash/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-shi-zen-relax/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-ninos-4-15-anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min-doble/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/padres-e-hijos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/luz-del-mediterraneo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranea/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-junior/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-30min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-adulto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-adulto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-cocktail-en-skybar-adulto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/vital-experience-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/contrastes-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/vital-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/premium-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/mil-y-una-noches/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/contrastes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/hammam-vital-experience/mil-y-una-noches-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/hidroterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-mahana/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-local-relajante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-hindu/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-relajante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa-para-2-adultos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-sueco/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/ritual-evasion-de-los-sentidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-reconfortante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/spa-60-descontracturante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-descontracturante-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/facial-detox/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/acceso-spa-ritual-del-cafe-2-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/ritual-relax-bodyna-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-aromaterapia-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-tejido-profundo-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-balines-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-relajante-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-relajante-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/acceso-spa-bodyna-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/reflexologia-podal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/ritual-bodyna-bali/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-marruecos-1h-45/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-masaje-aromatico-50-y-piscina-spa-2h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-aromatico-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-bangalore-80-y-piscina-spa-2h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-marruecos-105-y-piscina-spa-2h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-bangalore-1h-20/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-java-45-y-piscina-spa-2h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-java-45/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-domingos-de-piscina-spa-y-brunch/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-agua-y-relax/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-basic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-premium/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-2h-acceso/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/especial-parejas-2h-piscina-spa-masaje-aromatico-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-futura-mama-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-ayurvedico-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/masaje-personalizado-para-2-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/pack-romantico-2-noches/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/pack-romantico-1-noche/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-masaje-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-almuerzo-o-cena-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/hammam/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/jacuzzi-privado-con-moet-chandon/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/yhi-indian-scalp/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/aromaterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/bamboo-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/masaje-yhi-abhyanga/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/jacuzzi-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/paquete-para-ella/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/paquete-para-el/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/escapada-romantica/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/masaje-muscular-profundo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/tratamiento-facial-express/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/tratamiento-facial-vitamina-c/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/ritual-mediterraneo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/area-de-hidroterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/spa-privado-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-descontracturante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-podal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/exfoliacion-con-hidratacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/oasis-de-serenidad-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/reina-de-egipto-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-japones-facial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/sensual-and-care/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/peeling-de-cereza/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-cuatro-manos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-samay/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-prenatal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/spa-privado-vip-para-dos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/cerezaterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/masaje-craneo-facial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-abu-said/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-granada/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-morayma/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-farasha/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-aixa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-boabdil/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/bano-basico-masaje-de-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/bano-basico-masaje-de-15/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/abdomen-release-30-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/complete-diamond/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/antiedad-remodelante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/facial-express/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/circuito-de-aguas-45/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/corporal-y-facial-antioxidante-90-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/reflexologia-podal-30-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/piedras-calientes-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/indi-facial-express/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/deep-tissue-60-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/doble-exfoliacion/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/especial-embarazadas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/masaje-craneal-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/masaje-relajante-60-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/circuito-de-aguas-45-menores-de-14-anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/drenaje-detox-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-30min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pura-elegancia-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-personalizado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-afterwork-masaje-coctel/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-facial-kobido/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-morning-up-desayuno-zona-de-hidroterapia-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/friendly-majestic-duo-moments/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-quierete/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia-facial-antiedad-cena/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/diamond-energy-by-natura-bisse/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/oriental-touch-signature-treatment/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/c-vitamin-coctel-by-natura-bisse/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pack-majestic-morning-up/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/toque-de-calma-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/circuito-spa-experiencia-gastronomica-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/circuito-spa-masaje-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-thai-oil/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-facial-de-oriente/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/drenaje-linfatico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/spa-privado-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-deportivo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/pack-spa-experience-para-dos-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-relajante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-prenatal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-geo-serawa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-hawaiano/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/termal-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-relax-termal-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/friends-beauty-party-grupal-6-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/luxury-exclusive-pack-grupal-6-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-50-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/duo-luxury-love-con-masaje-50-min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-celebracion-grupal-6-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-grupal-6-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-30-min-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/duo-luxury-love-con-masaje-30-min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-celebracion-grupal-8-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-30-min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-50-min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-individual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-grupal-6-personas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-y-masaje-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-con-gastronomia-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-infantil/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-con-gastronomia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-2-adultos-2-ninos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-120-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-90-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/serenity-full-body-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/sport-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/detox-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/deep-tissue-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-nino-90/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hawaiian-lomi-lomi-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/masaje-infantil/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/abhyanga/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/ritual-de-la-vela-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/empieza-el-dia-con-armonia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-descontracturante-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-oriental-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-oriente-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-gastrobodyna-2-pax/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-sublime-de-la-polinesia-lomi-lomi-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-relax-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-relax-para-dos-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-relajese-y-disfrute/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-relajese-y-disfrute-2-pax/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-3/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-cena-de-lunes-a-viernes-noches/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-120-min-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-cena-de-lunes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-envoltura-de-algas-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/jacuzzi-masaje-en-pareja-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/ritual-purificante-y-renovador-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-expres-con-acido-hialuronico-i-q10-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-gastro-fin-de-semana/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abyhanga/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-gastro-fines-de-semana-y-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-en-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-almuerzo-de-lunes-a-viernes-mediodia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-lunes-a-viernes-festivos-no-incluidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/drenaje-linfatico-de-50-circuito-termal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 669",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 670",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 671",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-75/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 672",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 673",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 674",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/hidratante-source-marino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 675",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/ritual-al-andalus/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 676",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 677",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 678",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-75/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 679",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 680",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/circuito-spa-privado-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 681",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 682",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-75/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 683",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-55/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 684",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-holistico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 685",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-de-pindas-herbales/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 686",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-terapeutico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 687",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-relajante-45-min-comida/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 688",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/exfoliante-corporal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 689",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-relajante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 690",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-localizado-20-min-cena/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 691",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/circuito-spa-2h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 692",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/circuito-spa-1h/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 693",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 694",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 695",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 696",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/circuito-spa-senda-de-los-sentidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 697",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/limpieza-profunda-tratamiento-contorno-de-ojos-is-clinical/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 698",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/despertar-laguna/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 699",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 700",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suite-royal-therapy/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 701",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suenos-mediterraneos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 702",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-25min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 703",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-localizado-20/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 704",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-lomi-lomi/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 705",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-sueco/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 706",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-relajante-55-minutos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 707",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/full-moon-couple-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 708",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/half-moon-couple-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 709",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/crescent-moon-couple-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 710",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/jacuzzi-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 711",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/shiatsu-craneal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 712",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/shiatsu/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 713",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-deportivo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 714",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-30min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 715",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 716",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/love-massage-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 717",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 718",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/oasis-de-la-serenidad-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 719",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-essentially-beautiful-by-alqvimia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 720",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-m-v/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 721",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/gastro-wellness-experience-cena-spa-mascarilla/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 722",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wine-wellness-para-dos-especial-navidad/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 723",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-christmas-day-pass-especial-navidad/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 724",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-en-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 725",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-brunch-experience-desayuno-buffet-spa-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 726",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 727",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/day-pass-masaje-cocktail/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 728",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 729",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-relajante/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 730",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 731",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 732",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-l-v/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 733",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-s-d/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 734",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-s-d/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 735",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-m-v/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 736",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-s-d/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 737",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-best-sellers-christmas-edition-especial-navidad/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 738",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/masaje-terapeutico-50-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 739",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-masaje-antiestres-50-para-dos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 740",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-masaje-del-mundo-50-masaje-shampi-craneo-facial-25/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 741",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/masaje-terapeutico-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 742",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/masaje-del-mundo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 743",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/masaje-antiestres-50min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 744",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-60-min-sabados/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 745",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-90min-lunes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 746",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-60min-lunes-a-viernes/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 747",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/spa-madrid-sol-y-agua/circuito-spa-90min-sabados/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 748",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/aquae-private-experience-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 749",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/romantic-private-experience-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 750",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-private-experience-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 751",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 752",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-general/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 753",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/vinoterapia-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 754",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 755",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/chocolaterapia-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 756",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-relajante-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 757",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/oferta-circuito-hidrotermal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 758",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/circuito-hidrotermal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 759",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/aromaterapia-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 760",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-con-canas-de-bambu-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 761",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/circuito-hidrotermal-1-botella-de-vino/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 762",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/drenaje-linfatico/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 763",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-belleza-oriental-duo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 764",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen-duo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 765",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience-duo/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 766",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 767",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 768",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-embarazadas-nuad-kon-thong-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 769",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-de-bambu-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 770",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/envoltura-natural-para-una-piel-perfecta-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 771",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-con-aceite-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 772",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-4-manos-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 773",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pareja-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 774",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-wellness/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 775",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-pareja-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 776",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 777",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 778",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 779",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 780",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 781",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 782",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-zen-parejas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 783",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 784",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 785",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 786",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 787",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-60-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 788",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-30-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 789",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 790",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-deep-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 791",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 792",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 793",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-oxigen/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 794",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-go-sun-passion-fruit/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 795",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-30/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 796",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-60/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 797",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 798",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-cromoterapia-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 799",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/deep-massage/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 800",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-reiki-masaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 801",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/rituales-diosas/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 802",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-masaje-25-min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 803",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 804",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 805",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-cena-buffet/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 806",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-infantil-hasta-12-anos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 807",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 808",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 809",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 810",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 811",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-masaje-completo-de-75min-con-aceite-de-cerveza-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 812",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 813",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 814",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 815",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 816",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 817",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 818",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/beer-spa-madrid/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 819",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-masaje-50-min-lunes-a-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 820",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-masaje-25-min-lunes-a-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 821",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-masaje-25-min-lunes-a-domingos-inc-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 822",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-lunes-a-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 823",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-masaje-50-min-lunes-a-domingos-inc-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 824",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-deluxe-masaje-50-min-cava-y-bombones-lunes-a-jueves/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 825",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/madrid/esenzias-spa/onsen-duo-deluxe-masaje-50-min-cava-y-bombones-lunes-a-domingos-inc-festivos/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 826",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-y-masaje-aromaterapia/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 827",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 828",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas-1/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 829",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-masaje-y-banera-privada-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 830",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 831",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 832",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 833",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 834",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 835",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 836",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 837",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/acceso-al-spa-para-2/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.name ?? "Service landing: 838",
    path: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.props ?? false,
    meta: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta || {},
    alias: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.alias || [],
    redirect: ServiceDetail_46pagefDgXQAOVM1CWwaxy9LO_45eQfq01HfLyqe4qzDN_453VO3QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a1bbd15a-cbbc-469a-80ce-562ffb8aea04/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]